var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-toolbar',{attrs:{"color":"white"},scopedSlots:_vm._u([{key:"extension",fn:function(){return [_c('v-col',{staticClass:"w-100",attrs:{"cols":"12"}},[_c('v-tabs',{attrs:{"left":""},model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},_vm._l((_vm.tabs),function(item,box){return _c('v-tab',{key:box,on:{"change":function($event){return _vm.createDate(item.route)}}},[_vm._v(" "+_vm._s(item.name)+" ")])}),1)],1)]},proxy:true}])},[_c('v-col',{attrs:{"xs":"12","sm":"5","md":"4"}},[_c('v-text-field',{attrs:{"flat":"","hide-details":"","label":"Search","append-icon":"mdi-magnify","outlined":"","dense":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-spacer'),_c('div',[_c('download-csv',{attrs:{"data":_vm.getCsvData,"name":"callLogs.csv"}},[_c('v-btn',{staticClass:"mt-1 myTitle text-capitalize",attrs:{"color":"green","outlined":"","dark":""},on:{"click":function($event){return _vm.checkJsonData()}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"light":""}},[_vm._v("cloud_download")]),_vm._v(" Download ")],1)],1)],1),_c('div',{staticClass:"ml-2 mt-1"},[_c('v-btn',{attrs:{"dark":"","block":"","color":"rgb(231,87,83)","to":{
          name: 'AccountDetails',
          query: _vm.$route.query,
        }}},[_vm._v("Back")])],1),_c('hr')],1),_c('v-tabs-items',{model:{value:(_vm.selectedTab),callback:function ($$v) {_vm.selectedTab=$$v},expression:"selectedTab"}},[_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.callRecordsData,"search":_vm.search},scopedSlots:_vm._u([{key:"item.indexNo",fn:function(ref){
        var item = ref.item;
return [(item.direction == 'inbound')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","dark":""}},'v-icon',attrs,false),on),[_vm._v(" phone_callback ")])]}}],null,true)},[_c('span',[_vm._v("Inbound")])]):_vm._e(),(item.direction == 'outbound')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange","dark":""}},'v-icon',attrs,false),on),[_vm._v(" phone_forwarded ")])]}}],null,true)},[_c('span',[_vm._v("Outbound")])]):_vm._e()]}},{key:"item.caller_id_number",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.caller_id_number)+" ("+_vm._s(item.caller_id_name)+") ")]}},{key:"item.dialed_number",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.dialed_number)+" "),(item.callee_id_name)?_c('span',[_vm._v(" ("+_vm._s(item.callee_id_name)+") ")]):_vm._e()]}},{key:"item.cost",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDollarValue")(item.cost))+" + ")]}}],null,true)})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.callRecordsData,"search":_vm.search},scopedSlots:_vm._u([{key:"item.indexNo",fn:function(ref){
        var item = ref.item;
return [(item.direction == 'inbound')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","dark":""}},'v-icon',attrs,false),on),[_vm._v(" phone_callback ")])]}}],null,true)},[_c('span',[_vm._v("Inbound")])]):_vm._e(),(item.direction == 'outbound')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange","dark":""}},'v-icon',attrs,false),on),[_vm._v(" phone_forwarded ")])]}}],null,true)},[_c('span',[_vm._v("Outbound")])]):_vm._e()]}},{key:"item.caller_id_number",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.caller_id_number)+" ("+_vm._s(item.caller_id_name)+") ")]}},{key:"item.dialed_number",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.dialed_number)+" "),(item.callee_id_name)?_c('span',[_vm._v(" ("+_vm._s(item.callee_id_name)+") ")]):_vm._e()]}},{key:"item.cost",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDollarValue")(item.cost))+" + ")]}}],null,true)})],1)],1),_c('v-tab-item',[_c('v-card',{attrs:{"flat":""}},[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.callRecordsData,"search":_vm.search},scopedSlots:_vm._u([{key:"item.indexNo",fn:function(ref){
        var item = ref.item;
return [(item.direction == 'inbound')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","dark":""}},'v-icon',attrs,false),on),[_vm._v(" phone_callback ")])]}}],null,true)},[_c('span',[_vm._v("Inbound")])]):_vm._e(),(item.direction == 'outbound')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange","dark":""}},'v-icon',attrs,false),on),[_vm._v(" phone_forwarded ")])]}}],null,true)},[_c('span',[_vm._v("Outbound")])]):_vm._e()]}},{key:"item.caller_id_number",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.caller_id_number)+" ("+_vm._s(item.caller_id_name)+") ")]}},{key:"item.dialed_number",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.dialed_number)+" "),(item.callee_id_name)?_c('span',[_vm._v(" ("+_vm._s(item.callee_id_name)+") ")]):_vm._e()]}},{key:"item.cost",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDollarValue")(item.cost))+" + ")]}}],null,true)})],1)],1),_c('v-tab-item',[_c('v-card',{staticClass:"px-5",attrs:{"flat":""}},[_c('v-container',{attrs:{"fluid":""}},[(_vm.showCustomDate)?_c('v-row',{staticClass:"mx-0"},[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('p',{staticClass:"red--text caption mb-1"},[_vm._v(" *Note: Only 30 Days Call Detail Record will be available. ")])]),_c('v-col',{attrs:{"cols":"12","lg":"2","md":"3","sm":"12"}},[_c('v-menu',{ref:"menu1",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"From Date","persistent-hint":"","append-icon":"mdi-calendar","outlined":"","dense":""},model:{value:(_vm.date.to),callback:function ($$v) {_vm.$set(_vm.date, "to", $$v)},expression:"date.to"}},'v-text-field',attrs,false),on))]}}],null,false,3182310591),model:{value:(_vm.menu1),callback:function ($$v) {_vm.menu1=$$v},expression:"menu1"}},[_c('v-date-picker',{attrs:{"no-title":"","max":_vm.maxDate},on:{"change":_vm.fromDateChange,"input":function($event){_vm.menu1 = false}},model:{value:(_vm.date.to),callback:function ($$v) {_vm.$set(_vm.date, "to", $$v)},expression:"date.to"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"2","md":"3","sm":"12"}},[_c('v-menu',{attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"To Date","persistent-hint":"","append-icon":"mdi-calendar","readonly":"","outlined":"","dense":""},model:{value:(_vm.date.from),callback:function ($$v) {_vm.$set(_vm.date, "from", $$v)},expression:"date.from"}},'v-text-field',attrs,false),on))]}}],null,false,720628207),model:{value:(_vm.menu2),callback:function ($$v) {_vm.menu2=$$v},expression:"menu2"}},[_c('v-date-picker',{attrs:{"no-title":"","min":_vm.minToDate,"max":_vm.maxToDate,"disabled":_vm.disabledToDate},on:{"input":function($event){_vm.menu2 = false}},model:{value:(_vm.date.from),callback:function ($$v) {_vm.$set(_vm.date, "from", $$v)},expression:"date.from"}})],1)],1),_c('v-col',{attrs:{"cols":"12","lg":"2","md":"3","sm":"12"}},[_c('v-btn',{staticClass:"myTitle text-capitalize",attrs:{"color":"primary","outlined":"","dense":"","dark":""},on:{"click":function($event){return _vm.getCallDetailList()}}},[_vm._v(" Search ")])],1)],1):_vm._e()],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.callRecordsData,"search":_vm.search},scopedSlots:_vm._u([{key:"item.indexNo",fn:function(ref){
        var item = ref.item;
return [(item.direction == 'inbound')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"green","dark":""}},'v-icon',attrs,false),on),[_vm._v(" phone_callback ")])]}}],null,true)},[_c('span',[_vm._v("Inbound")])]):_vm._e(),(item.direction == 'outbound')?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
        var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({attrs:{"color":"orange","dark":""}},'v-icon',attrs,false),on),[_vm._v(" phone_forwarded ")])]}}],null,true)},[_c('span',[_vm._v("Outbound")])]):_vm._e()]}},{key:"item.caller_id_number",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.caller_id_number)+" ("+_vm._s(item.caller_id_name)+") ")]}},{key:"item.dialed_number",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(item.dialed_number)+" "),(item.callee_id_name)?_c('span',[_vm._v(" ("+_vm._s(item.callee_id_name)+") ")]):_vm._e()]}},{key:"item.cost",fn:function(ref){
        var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("getDollarValue")(item.cost))+" + ")]}}],null,true)})],1)],1)],1),_c('loading',{attrs:{"active":_vm.isLoading,"loader":_vm.loader}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }